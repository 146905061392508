import React from 'react'
import NotFoundPage from '../components/NotFoundPage'

function noPage() {
  return (
    <div>
       <NotFoundPage />
    </div>
  )
}

export default noPage