import React from 'react'

import Navbar from '../components/Navbar';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

function contactPage() {
  return (
    <div>
        <Navbar />
        <ContactSection />
        <Footer />
    </div>
  )
}

export default contactPage