import React from 'react';
import { FaSearch, FaDownload } from 'react-icons/fa'; // Import the Font Awesome icons

const InvoiceSection = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex justify-center items-center">
      <div className="text-white text-center space-y-6 md:space-y-8 px-4">
        <h1 className="text-4xl md:text-5xl">
          Hey! Thanks for stopping by!
        </h1>
        <h2 className="text-lg md:text-xl">
          To get started, please enter an invoice number, P.O. number, or an email address below.
        </h2>
        <input
          type="text"
          className="w-full md:w-400px text-center bg-white text-black rounded-md py-3 px-4"
          placeholder="Enter your information"
        />
        <div className="flex flex-col md:flex-row md:justify-center md:space-x-4 space-y-4 md:space-y-0">
          <button className="w-auto md:w-auto py-3 px-20 bg-white text-black rounded-md hover:bg-gray-300 transition-colors">
            <FaSearch className="inline-block mr-2" /> Lookup Invoice {/* Add search icon */}
          </button>
          <button className="w-auto md:w-auto py-3 px-20 bg-white text-black rounded-md hover:bg-gray-300 transition-colors">
            <FaDownload className="inline-block mr-2" /> Download Invoice {/* Add download icon */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSection;
