import React from 'react';
import NetworkingBackground from '../../assets/backgrounds/internet.jpg';

function NetworkingSection() {
  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${NetworkingBackground})`,
        }}
      ></div>

      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative container mx-auto p-4 md:p-8 flex items-center">
        <div className="md:w-1/2">
          <h1 className='text-4xl text-white text-bold underline pb-4'>Networking</h1>
          <p className="text-lg text-white">
            In the modern world, the Internet has transformed into a vital necessity, 
            influencing how we communicate, learn, and do business. To fully leverage its power, 
            a well-structured network is essential. This network acts as the backbone for handling the 
            increasing demands of online activities, ensuring smooth streaming, seamless video conferencing, 
            secure transactions, and more. Especially during high-demand events, a robust network prevents 
            disruptions, maintaining productivity and user satisfaction. By investing in a solid network 
            foundation, we not only accommodate current needs but also prepare for future technological 
            advancements, securing the Internet's role as a catalyst for progress.
          </p>
        </div>
      </div>
    </div>
  );
}

export default NetworkingSection;
