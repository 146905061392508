import React from 'react';
import backgroundImage from '../../assets/backgrounds/wrinkled.jpg';

const BackgroundImage = () => {
  return (
    <div
      className="fixed w-full h-auto inset-0 -z-50 bg-repeat-y"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    ></div>
  );
};

export default BackgroundImage;