import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

import logo from '../assets/logo-white-min.png';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-black bg-opacity-10 backdrop-blur-md drop-shadow-2xl z-20">
      <div className="mx-auto max-w-7xl px-4">
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center">
            {/* Logo */}
            <img src={logo} alt="Logo" className="h-10 w-auto" />
          </div>
          <div className="hidden md:flex space-x-8">
            {/* Nav Links */}
            <a href="/" className="text-gray-400 text-xl md:text-2xl font-thin hover:text-white hover:underline">HOME</a>
            <a href="/about" className="text-gray-400 text-xl md:text-2xl font-thin hover:text-white hover:underline">ABOUT</a>
            <a href="/contact" className="text-gray-400 text-xl md:text-2xl font-thin hover:text-white hover:underline">CONTACT</a>
            <a href="/invoices" className="px-4 bg-white text-gray-400 text-xl md:text-2xl font-thin rounded-md hover:bg-[#164b56] hover:text-white">
            INVOICES
            </a>
          </div>
          <div className="md:hidden">
            {/* Mobile Menu Icon */}
            {mobileMenuOpen ? (
              <AiOutlineClose
                onClick={toggleMobileMenu}
                className="text-white text-2xl cursor-pointer"
              />
            ) : (
              <AiOutlineMenu
                onClick={toggleMobileMenu}
                className="text-white text-2xl cursor-pointer"
              />
            )}
          </div>
        </div>
        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div
            className={`md:hidden ${
              mobileMenuOpen
                ? 'fixed top-[72px] left-0 w-full h-[calc(100vh-72px)] bg-black bg-opacity-50 backdrop-blur-md text-white py-4 px-8 overflow-y-auto transform translate-x-0 transition-transform duration-500 ease-in-out'
                : 'transform -translate-x-full'
            }`}
          >
            <ul className="flex flex-col items-center uppercase text-3xl font-thin space-y-10">
              <li className="w-1/2 p-4 border-b border-white">
                <a href="/" className="text-white flex justify-center">Home</a>
              </li>
              <li className="w-1/2 p-4 border-b border-white">
                <a href="/about" className="text-white flex justify-center">About</a>
              </li>
              <li className="w-1/2 p-4 border-b border-white">
                <a href="/contact" className="text-white flex justify-center">Contact</a>
              </li>
              <li>
              <a href="/invoices" className="w-[50%] px-20 py-4 bg-black text-white font-thin rounded-md hover:bg-gray-800">
              INVOICES
              </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;




