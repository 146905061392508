import React from 'react'

import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Brands from '../components/home/Brands';
import CCTVSection from '../components/home/CCTV';
import NetworkingSection from '../components/home/Networking';
import Footer from '../components/Footer';
import Filler from '../components/home/Filler';
import BackgroundImage from '../components/home/BackgroundImage';

function home() {
  return (
    <div>
        <BackgroundImage />
        <Navbar />
        <Hero />
        <Filler />
        <CCTVSection />
        <Filler />
        <NetworkingSection />
        <Filler />
        <Footer />
    </div>
  )
}

export default home