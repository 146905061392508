import React from 'react'

import logo from '../assets/logo-white-min.png';

function comingSoon() {
  return (
    <div className="bg-black h-screen flex flex-col items-center justify-center text-white">
      <img
        src={logo}
        alt="Coming Soon"
        className="w-[400px] mb-6"
      />
      <p className="text-xl font-bold">Coming soon, please check back later.</p>
    </div>
  )
}

export default comingSoon