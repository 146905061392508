import React from 'react'

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import InvoiceSection from '../components/InvoiceSection';

function invoices() {
  return (
    <div>
        <Navbar />
        <InvoiceSection />
        <Footer />
    </div>
  )
}

export default invoices