import React from 'react';

const PrivacyNotice = () => {
  return (
    <div className="top-[72px] bg-black h-screen flex flex-col items-center justify-center">
      
      <h1 className="text-white text-center text-2xl pt-10">Privacy Policy Notice</h1>

      <p className='text-white text-center py-4 px-4'>Effective Date: August 2023 </p>

      <p className='text-white text-center w-2/3'>
      This Privacy Policy Notice ("Notice") explains how Riamant LLC 
      ("we," "our," or "us") collects, uses, shares, and protects personal 
      information about you, the user, when you interact with our website, 
      products, services, and other offerings (collectively referred to as "Services"). 
      This Notice also outlines your rights and choices regarding your personal information.
      </p>

      <h1 className="text-white text-center text-2xl pt-10">Information We Collect</h1>
      The types of personal information we may collect include, but are not limited to:
      <ul className='list-disc text-white'>
      <li>Contact information (e.g., name, email address, phone number)</li>
      <li>Demographic information (e.g., age, gender, location)</li>
      <li>Account credentials (e.g., username, password)</li>
      <li>Payment information (e.g., credit card details)</li>
      <li>Usage data (e.g., browsing history, interactions with our Services)</li>
      <li>Communication preferences</li>
      <li>Any other information you provide voluntarily</li>
      </ul>
      <p className='text-white text-center w-2/3'>

      </p>

      </div>
  );
};

export default PrivacyNotice;