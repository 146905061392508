import React, { useState } from 'react';

import backgroundImg from '../assets/backgrounds/map.jpg'

const ContactSection = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        service: '',
        comments: '',
      });
    
      const [errorMessage, setErrorMessage] = useState('');
      const [submitted, setSubmitted] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');
    
        if (
          formData.firstName === '' ||
          formData.lastName === '' ||
          formData.phoneNumber === '' ||
          formData.email === '' ||
          formData.service === ''
        ) {
          setErrorMessage('Please fill out all required fields.');
          return;
        }
    
        // Simulate submission
        setSubmitted(true);
        setTimeout(() => {
          window.location.href = '/';
        }, 4000);
      };
    
      return (
        <div className="flex relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${backgroundImg})` }}>
        <div className="absolute inset-0 h-full bg-black opacity-50"></div>
          {submitted ? (
            <p className="flex my-auto mx-auto text-white text-4xl text-center z-10">
              We'll be in touch! Thank you!
            </p>
          ) : (
            <form
            className="bg-opacity-50 bg-gray-100 backdrop-blur-md rounded-md p-8 space-y-4 w-full max-w-md my-auto mx-auto z-10"
            onSubmit={handleSubmit}
            >               
              <h1 className="text-white text-4xl">Let's get to know each other.</h1>
              <h2 className="text-white text-xl">Please enter your information below.</h2>
    
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="bg-white backdrop-blur-md text-black w-1/2 p-2 rounded-md border-0"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="bg-white backdrop-blur-md text-black w-1/2 p-2 rounded-md border-0"
                  onChange={handleChange}
                />
              </div>
    
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                className="bg-white backdrop-blur-md text-black w-full p-2 rounded-md border-0"
                onChange={handleChange}
              />
    
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="bg-white backdrop-blur-md text-black w-full p-2 rounded-md border-0"
                onChange={handleChange}
              />
    
              <p className="text-white">What services are you interested in?</p>
              <select
                name="service"
                className="bg-white backdrop-blur-md text-black w-full p-2 rounded-md border-0"
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select an option
                </option>
                <option value="Cameras">Cameras</option>
                <option value="Networking">Networking</option>
                <option value="CAD">CAD</option>
                <option value="Subcontracting">Subcontracting</option>
                <option value="Other">Other</option>
              </select>
    
              <p className="text-white">Have any questions or comments?</p>
              <textarea
                name="comments"
                rows="4"
                className="bg-white backdrop-blur-md text-black w-full p-2 rounded-md border-0"
                onChange={handleChange}
              />
    
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    
                <button
                type="submit"
                className="bg-black text-white p-2 rounded-md w-full"
                >
                Let's get in touch!
              </button>
            </form>
          )}
        </div>
      );
    };
    
export default ContactSection;
