import React, { useState, useEffect } from 'react';
import { RiToolsFill } from 'react-icons/ri';

import logo from '../assets/logo-white-min.png'

const NotFoundPage = () => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      // Redirect user to the home page
      window.location.href = '/';
    }

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
      <div className="flex items-center">
        <RiToolsFill className="text-8xl" />
      </div>
      <div className="my-8">
        <img
          src={logo}
          alt="Placeholder"
          className="max-w-full h-auto px-20"
        />
      </div>
      <p className="text-2xl text-center">
        Uh-oh, I think you stumbled somewhere you shouldn't be.
      </p>
      <p className="text-2xl text-center pt-10">Let's get you home.</p>
      <p className="text-4xl mt-8 pt-10">{countdown}</p>
    </div>
  );
};

export default NotFoundPage;
