import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

import backgroundImg from '../assets/backgrounds/map.jpg';
import heroImg from '../assets/logo_white.png';

const Hero = () => {
  const [heroTypewriter] = useTypewriter({
    words: [
      'Camera Deployments',
      'Server Management',
      'Remote Management',
      'Network Solutions',
      'CAD Design',
      'Structured Cabling',
      'Subcontracting'
    ],
    loop: 0
  });

  return (
    <div className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center">
        <div className="max-w-[1600px] mx-auto md:flex md:flex-row md:justify-center md:items-center">
          <div className="md:w-1/2 md:order-1 md:text-center md:pr-16 text-center">
            <h1 className="md:text-7xl sm:text-6xl text-5xl md:py-6 text-white drop-shadow-2xl">
              Why Choose Riamant?
            </h1>
            <div className="flex justify-center md:text-4xl sm:text-3xl text-2xl text-white">
              <p className="font-light py-4 pr-4">
                Fast & Flexible <span className='md:text-4xl sm:text-3xl text-2xl font-light  text-gray-300'>{heroTypewriter}</span>
                <Cursor />
              </p>
            </div>
            <p className="md:text-3xl text-2xl font-thin text-gray-200 py-10">
              Serving both Commercial and Residential Customers
            </p>
          </div>
          <div className="md:w-1/2 md:order-2 md:flex md:justify-center md:items-center">
            <img src={heroImg} alt="Hero" className="md:w-[50%] w-[70%] mx-auto md:mb-0 mb-8" /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;





