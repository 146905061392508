import React from 'react'
import DripStone from '../../assets/brandLogos/logo-dripstone.png'
import HikVision from '../../assets/brandLogos/logo-hikvision.png'
import Ubiquiti from '../../assets/brandLogos/logo-ubiquiti.png'
import Netgear from '../../assets/brandLogos/logo-netgear.png'
import AutoDesk from '../../assets/brandLogos/logo-autodesk.png'
import Windows from '../../assets/brandLogos/logo-windows.png'

function Brands() {
  return (
    <div className='w-full bg-transparent backdrop-blur-sm pb-16 pt-6 px-4 mx-auto text-center flex flex-col justify-center z-10'>
        <div className='max-w-[1240px] max-auto self-center'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-3'>            
            <img className='flex pt-10 mx-auto self-center' src={HikVision} width={236} height={30} alt='HikVision'/>
            <img className='flex pt-10 mx-auto self-center' src={Ubiquiti} width={110} height={30} alt='Ubiquiti'/>
            <img className='flex pt-10 mx-auto self-center' src={Netgear}  width={207} height={30} alt='Netgear'/>
            <img className='flex pt-10 mx-auto self-center' src={DripStone} width={135} height={30} alt='DripStone'/>
            <img className='flex pt-10 mx-auto self-center' src={AutoDesk}  width={180} height={30} alt='AutoDesk'/>
            <img className='flex pt-10 mx-auto self-center' src={Windows}  width={134} height={30} alt='Windows'/>
            </div>
        </div>
    </div>
  )
}

export default Brands