import React from 'react';
import cctvBackground from '../../assets/backgrounds/cctv.jpg';

function CctvSection() {
  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${cctvBackground})`,
        }}
      ></div>

      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative container mx-auto p-4 md:p-8">
        <div className="md:flex md:justify-end">
          <div className="md:w-1/2">
            <h1 className='text-4xl text-white text-bold underline pb-4 md:flex md:justify-end text-right'>Security Cameras</h1>
            <p className="text-lg text-white text-right">
              Security cameras are vital for modern safety. They deter crime, provide real-time monitoring,
              and offer crucial evidence. By promoting accountability, they create safer environments,
              aid law enforcement, and improve operational efficiency. In today's tech-driven world,
              security cameras are essential for comprehensive security strategies,
              enhancing overall safety and protection.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CctvSection;



