import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

import logo from '../assets/logo-white-min.png';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          {/* Logo */}
          <img src={logo} alt="Logo" className="h-7" />
        </div>
        <div className="mb-4 md:mb-0 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          {/* Email Subscription */}
          <input
            type="email"
            placeholder="Enter your email"
            className="px-4 py-2 w-full rounded-sm bg-gray-800 mb-2 md:mb-0"
          />
          <button className="px-4 py-2 bg-white text-black rounded-sm hover:bg-gray-300">
            Subscribe
          </button>
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-8">
          <div className="text-center md:text-left">
            <p className="font-semibold mb-2 md:mb-4">Partnership</p>
            <a href="#" className="text-gray-300 hover:text-white block">Websites</a>
            <a href="#" className="text-gray-300 hover:text-white block">Social Media</a>
            <a href="#" className="text-gray-300 hover:text-white block">Branding</a>
          </div>
          <div className="text-center md:text-left">
            <p className="font-semibold mb-2 md:mb-4">About</p>
            <a href="#" className="text-gray-300 hover:text-white block">Our Projects</a>
            <a href="#" className="text-gray-300 hover:text-white block">Careers</a>
          </div>
          <div className="text-center md:text-left">
            <p className="font-semibold mb-2 md:mb-4">Support</p>
            <a href="#" className="text-gray-300 hover:text-white block">Support Request</a>
            <a href="#" className="text-gray-300 hover:text-white block">Contact</a>
            <a href="/privacy" className="text-gray-300 hover:text-white block">Privacy Policy</a>
          </div>
        </div>
      </div>
      <hr className="mt-8 mx-5 border-white opacity-25 border-t-2" />
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center mt-4">
        <p className="text-gray-300 text-sm mb-4 md:mb-0">
          © 2023 Riamant LLC. All rights reserved.
        </p>
        <div className="flex space-x-4">
          <a href="#" className="text-white hover:text-gray-300">
            <FaFacebook size={24} />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <FaTwitter size={24} />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <FaInstagram size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


