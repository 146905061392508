import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Riamant.css';

import Home from './pages/home'
import ContactSection from './pages/contactpage';
import Invoices from './pages/invoices'
import NoPage from './pages/noPage';
import Privacy from './pages/privacy';
import ComingSoon from './pages/comingSoon';

export default function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<ComingSoon />} />
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<ContactSection />} />
          <Route path='/invoices' element={<Invoices />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='*' element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


