import React from 'react'
import PrivacyNotice from '../components/PrivacyNotice'
import Navbar from '../components/Navbar'

function Privacy() {
  return (
    <div>
        <Navbar />
       <PrivacyNotice />
    </div>
  )
}

export default Privacy